import React, {useState} from "react";
import addToMailchimp from 'gatsby-plugin-mailchimp'
import styled from "styled-components";

const ErrorP = styled.p`
  a {
    text-decoration: underline;
  }
`

export function NewsletterSignup() {
  const [showSuccess, setShowSuccess] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [showPrivateBrowser, setShowPrivateBrowser] = useState(false)
  async function onSubmit(e) {
    e.preventDefault()

    const [name, email] = Array.from(e.target.querySelectorAll('input')).map(({value}) => value)
    const cleanName = name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
    let result;
    try {
      result = await addToMailchimp(email, {
        FNAME: cleanName,
        PATHNAME: window.location.href.replace("https://kurthutten.com/", ""),
      })
      if(result.result === 'error') {
        setShowSuccess(false)
        setErrorMsg(result.msg)
        setShowPrivateBrowser(false)
      } else {
        setShowSuccess(true)
        setErrorMsg('')
        setShowPrivateBrowser(false)
      }
    } catch (e) {
      // TODO send email and details to static kit, and probably the event to g-analytics
      setShowPrivateBrowser(true)
    }
  }
  return (
    <div className="max-w-3xl px-4 mx-auto text-indigo-300 font-lora" >
    {showSuccess && <>
      <p className="text-teal-500">Hooray 🎉</p>
      <p className="text-teal-500">The newsletter is only getting started so I'm super appreciative of early subscribers like you.</p>
      <p className="text-teal-500">Could you do me a favour? You'll get a welcome email though it's probably in spam. Could you move it into your inbox?</p>
      <p className="text-teal-500">Companies like google are unlikely to trust my emails yet, so moving my email out of spam goes a long way in showing your provider that it's legitimate. Thanks.</p>
    </>}
    {errorMsg && <ErrorP className="text-red-400" dangerouslySetInnerHTML={{__html: '⚠️ ' + errorMsg}} />}
    {showPrivateBrowser && <>
      <p className="text-red-400" >Oh snap ⚠️</p>
      <p className="text-red-400" >We hit an issue. This can happen in a private/incognito browser window. Please try again in a regular browser window. Or send me an email: kurt@kurthutten.com</p>
    </>}
    <div className="justify-center flex mt-10">
      { !showSuccess && !errorMsg && !showPrivateBrowser && <form onSubmit={onSubmit} className="flex border border-indigo-300 p-3 rounded-lg items-center">
        <div>
          <label className='block text-sm font-lora text-indigo-400'>name :</label>
          <input
            className="bg-transparent text-indigo-300 font-lora block mb-3 focus:outline-none"
            type="text"
            required={true}
            placeholder="me"
          />
          <label className='block text-sm font-lora text-indigo-400'>email :</label>
          <input
            className="bg-transparent text-indigo-300 font-lora block focus:outline-none mr-6"
            type="email"
            required={true}
            placeholder="me@tech.com"
          />
        </div>
        <button className="bg-pink-400 text-indigo-800 p-4 px-6 rounded shadow-lg border border-transparent hover:border-indigo-200">
          Join Newsletter
        </button>
      </form>}
    </div>
  </div>
  );
}
